import * as React from 'react';
import { navigate } from 'gatsby';
import { Box, Spinner, Flex } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import SEO from '../components/seo/seo';
import awsmobile from '../utils/aws-exports';
import { setInitialUser } from '../state/user/userSlice';

Amplify.configure(awsmobile);

const App = (): JSX.Element => {
  const location = useLocation();
  const searchParams = parse(location.search); // => {init: "true"}

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (searchParams?.id) {
      localStorage.setItem('invitationTokenid', searchParams?.id);
    }
    Auth.currentAuthenticatedUser()
      .then((res) => {
        dispatch(setInitialUser(res));
        navigate('/app');
      })
      .catch((e) => {
        navigate('/signin');
        return null;
      });
  }, []);

  return (
    <>
      <Box marginBottom="30px" overflow="clip">
        <Box minH="90.5vh">
          <SEO title="Home" />
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              type: 'spring',
              mass: 0.35,
              stiffness: 75,
              duration: 0.3,
            }}
          >
            <Flex height="100vh" align="center" justify="space-evenly">
              <Spinner color="twitter.700" size="xl" />
            </Flex>
          </motion.div>
        </Box>
      </Box>
    </>
  );
};

export default App;
